import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { CustomerClassifyResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  getResourceStatusOptions,
  getStatusClass,
  getStatusI18Key,
  messageError,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { ResourceStatusEnum } from '@/resource/enum';
import { cloneDeep } from 'lodash-es';
import { customerClassifyService } from '@/api';
import AddCustomerClassify from './add-customer-classify/add-customer-classify.vue';
@Component({
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, AddCustomerClassify }
})
export default class CustomerClassify extends Vue {
  public tableOption: OsTableOption<CustomerClassifyResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerClassifyResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'categoryCode',
      label: 'classify.code',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    { prop: 'categoryName', label: 'classify.name', minWidth: '150px', showOverflowTooltip: true },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'customer:category:save',
      handleClick: (): void => {
        this.openClassifyDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'customer:category:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteClassify();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'customer:category:editStatus',
      handleClick: (): void => {
        this.batchUpdateClassifyStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'customer:category:editStatus',
      handleClick: (): void => {
        this.batchUpdateClassifyStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<CustomerClassifyResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'customer:category:edit',
        handleClick: (item: CustomerClassifyResource): void => {
          this.openClassifyDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'customer:category:delete',
        handleClick: (item: CustomerClassifyResource): void => {
          this.deleteClassify(item);
        }
      }
    ]
  };
  public editRow: CustomerClassifyResource | null = null;
  public dialogVisible = false;
  public totalData = 0;
  private selectedRows: Array<CustomerClassifyResource> = [];

  private queryForm: Partial<{
    keywords?: string;
    status?: ResourceStatusEnum | null;
  }> = {
    keywords: '',
    status: null
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public created(): void {
    this.lodaData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.classifyTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.lodaData();
  }

  public pagingData(): void {
    this.lodaData();
  }

  public handleSelectionChange(selectedData: Array<CustomerClassifyResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: CustomerClassifyResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  private openClassifyDialog(data: CustomerClassifyResource | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CustomerClassifyResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add') {
        x.disabled = value.length === 0;
      }
    });
  }

  private deleteClassify(data: CustomerClassifyResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await customerClassifyService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeleteClassify(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await customerClassifyService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private lodaData(): void {
    this.tableOption.loading = true;
    customerClassifyService
      .getList(this.queryForm as CustomerClassifyResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private batchUpdateClassifyStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    customerClassifyService
      .batchUpdateClassifyStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }
}
